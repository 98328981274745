:root {
  --text-normal: white;
}

.App {
  text-align: center;
  background-color: #161f33;
  color: "white";
  max-height: 100vh;
  overflow: hidde;
  /* //background: rgb(25,0,36);
	//background: radial-gradient(circle, rgba(25,0,36,0.9808298319327731) 0%, rgba(95,9,121,1) 100%, rgba(95,9,121,1) 100%); */
}

.infoHeader,
.bottomBar {
  //background: rgb(25,0,36);
  //background: radial-gradient(circle, rgba(25,0,36,0.9808298319327731) 0%, rgba(95,9,121,1) 100%, rgba(95,9,121,1) 100%);
  background-color: #3d3d58;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 99vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-headerMobile {
  min-height: 99vh;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.teamScoreContainer {
  -webkit-box-shadow: 0 0 40px #8f60c8;
  -moz-box-shadow: 0 0 40px #8f60c8;
  box-shadow: 0 0 40px #8f60c8;
}

.allNumbers,
.numberBox {
  -webkit-box-shadow: 0 0 40px #c08ffc;
  -moz-box-shadow: 0 0 40px #c08ffc;
  box-shadow: 0 0 40px #c08ffc;
}

.progressLabel {
  color: "white";
}

.minusCounterButton,
.plusCounterButton {
  cursor: pointer;
  height: 4vh;
  width: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #262f43;
}

.plusCounterButton {
  //margin-top: 1vh;
  color: green;
}

.minusCounterButton {
  //margin-bottom: 1vh;
  color: red;
}

.plusCounterButton:hover {
  background-color: #4f5b75;
  //color: white;
}

.minusCounterButton:hover {
  background-color: #4f5b75;
  //color: white;
}

.numberBox {
  background-color: lightblue;
}

.numberBox:hover {
  background-color: #857daa;
}

.button {
  background-color: #262f43;
}

.button:hover {
  background-color: #4f5b75;
}
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}
