.progressStepCircle{
	background-color:lightcoral;
}

.previousBtnName{
    color:white;
}

.button:hover{
    cursor: pointer;
}

.stepper{
    color: white;
}